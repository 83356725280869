.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 160px;
}

.App-link {
  color: #09d3ac;
}

:root {
  --optum-accessible-orange: #c25608; /* can be used for text */
  --optum-brand-orange: #e87722;
  --optum-white: #fff;
  --optum-brand-gold: #f2b411;
  --optum-gray-1: #282a2e; /* can be used for text */
  --optum-gray-2: #434448; /* can be used for text */
  --optum-gray-3: #63666a; /* can be used for text */
  --optum-gray-4: #888b8d;
  --optum-gray-5: #b1b3b3;
  --optum-gray-6: #d0d0ce;
  --optum-gray-7: #efefee;
  --optum-red-1: #a32a2e; /* can be used for text */
  --optum-red-2: #d13f44;
  --optum-purple-1: #422c88; /* can be used for text */
  --optum-purple-2: #8061bc;
  --optum-teal-1: #078576; /* can be used for text */
  --optum-teal-2: #6fc1b1;
  --optum-green-1: #627d32; /* can be used for text */
  --optum-green-2: #90bc53;
  --optum-text-link-blue: #316bbe; /* can be used for text */
  --optum-text-link-dark-blue: #005e9d; /* can be used for text */
  --optum-error-state-red: #e32315; /* can be used for text */
  --optum-go-green-1: #627d32; /* can be used for text */
}

.centered-cell {
  text-align: left;
}
.centered-cell .ag-cell-wrapper {
  display: inline-block !important;
  vertical-align: middle;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.modal-wide {
  width: 90%;
  align-content: center;
  max-width: none !important;
}

.modal-wide2 {
  width: 50%;
  align-content: center;
  max-width: none !important;
}

.modal-60wide {
  width: 60%;
  max-width: none !important;
}

.order-detail-modal-container {
  width: 60%;
  align-content: center;
  max-width: none !important;
}

.change-request-modal-container {
  width: 60%;
  align-content: center;
  max-width: none !important;
}

#uniqueAGGridID1 .ag-root .ag-header {
  display: none !important;
}
#uniqueAGGridID2 .ag-root .ag-header {
  display: none !important;
}
#uniqueAGGridID2 .ag-layout-auto-height {
  min-height: 120px !important;
}
#dx-inbound-grid .ag-panel {
  min-height: 50% !important;
  max-height: 95% !important;
}
#dx-inbound-grid .ag-panel .ag-column-select-header {
  display: none;
}

.ag-theme-balham,
.ag-theme-balham-dark {
  --ag-header-background-color: #d9f6fa !important;
  --ag-header-foreground-color: #002677 !important;
  --ag-row-hover-color: #fffae9 !important;
  --ag-selected-row-background-color: #faf8f2 !important;
  --ag-balham-active-color: #002677 !important;
  --ag-header-column-resize-handle-color: #002677 !important;
  font-size: 0.7vw !important;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
}

.ag-cell-label-container {
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}

.header-bolded {
  font-weight: bold;
}
